import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRobot, FaPaperPlane, FaMicrophone, FaStop, FaLightbulb, FaBook } from 'react-icons/fa';
import styles from '../styles/AIStudyAssistant.module.css';

const AIStudyAssistant = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      type: 'assistant',
      content: 'Hello! I\'m your AI study assistant. How can I help you today?',
      timestamp: new Date(),
    }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [suggestions, setSuggestions] = useState([
    'Explain quantum mechanics',
    'Help me create a study schedule',
    'Quiz me on recent material',
    'Summarize my notes'
  ]);
  const messagesEndRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    // Add user message
    const userMessage = {
      type: 'user',
      content: inputValue,
      timestamp: new Date(),
    };

    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setIsTyping(true);

    // Simulate AI response (replace with actual AI integration)
    setTimeout(() => {
      const aiResponse = {
        type: 'assistant',
        content: 'I understand you\'re asking about ' + inputValue + '. Let me help you with that...',
        timestamp: new Date(),
      };
      setMessages(prev => [...prev, aiResponse]);
      setIsTyping(false);
    }, 1500);
  };

  const handleVoice = () => {
    setIsRecording(!isRecording);
    // Implement voice recognition logic
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    handleSend();
  };

  return (
    <>
      <motion.div
        className={styles.assistantButton}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FaRobot />
      </motion.div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.assistantWindow}
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 50, scale: 0.3 }}
            transition={{ type: 'spring', damping: 20 }}
          >
            <div className={styles.header}>
              <div className={styles.assistantInfo}>
                <FaRobot className={styles.assistantIcon} />
                <div>
                  <h3>AI Study Assistant</h3>
                  <span className={styles.status}>Always ready to help</span>
                </div>
              </div>
              <button
                className={styles.closeButton}
                onClick={() => setIsOpen(false)}
              >
                ×
              </button>
            </div>

            <div className={styles.messagesContainer}>
              {messages.map((message, index) => (
                <motion.div
                  key={index}
                  className={`${styles.message} ${styles[message.type]}`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  {message.type === 'assistant' && (
                    <FaRobot className={styles.messageIcon} />
                  )}
                  <div className={styles.messageContent}>
                    <p>{message.content}</p>
                    <span className={styles.timestamp}>
                      {new Date(message.timestamp).toLocaleTimeString()}
                    </span>
                  </div>
                </motion.div>
              ))}
              {isTyping && (
                <div className={`${styles.message} ${styles.assistant}`}>
                  <FaRobot className={styles.messageIcon} />
                  <div className={styles.typingIndicator}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            <div className={styles.suggestions}>
              {suggestions.map((suggestion, index) => (
                <motion.button
                  key={index}
                  className={styles.suggestionChip}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.length > 20 
                    ? suggestion.substring(0, 20) + '...' 
                    : suggestion}
                </motion.button>
              ))}
            </div>

            <div className={styles.inputContainer}>
              <textarea
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Ask me anything..."
                rows="1"
                className={styles.input}
              />
              <div className={styles.inputActions}>
                <motion.button
                  className={styles.voiceButton}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={handleVoice}
                >
                  {isRecording ? <FaStop /> : <FaMicrophone />}
                </motion.button>
                <motion.button
                  className={styles.sendButton}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={handleSend}
                  disabled={!inputValue.trim()}
                >
                  <FaPaperPlane />
                </motion.button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AIStudyAssistant;
