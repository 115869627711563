import React from 'react';
import styles from '../styles/HomePage.module.css';
import { 
  FaSearch, 
  FaPlus,
  FaRegLightbulb,
  FaTrophy,
  FaMicrophone,
  FaFileAlt,
  FaPencilAlt,
  FaBrain,
  FaCloud,
  FaMagic,
  FaArrowRight
} from "react-icons/fa";
import AIStudyAssistant from './AIStudyAssistant';

const HomePage = () => {
  const recentSets = [
    { id: 1, title: "React Fundamentals", cards: 45, lastAccessed: "2 hours ago", progress: 75 },
    { id: 2, title: "JavaScript ES6+", cards: 30, lastAccessed: "1 day ago", progress: 60 },
    { id: 3, title: "History Dates", cards: 25, lastAccessed: "2 days ago", progress: 40 },
  ];

  return (
    <div className={styles.homeContainer}>
      {/* Hero Section */}
      <section className={styles.hero}>
        <div className={styles.heroContent}>
          <h1>Transform Your Study Experience</h1>
          <p>Harness the power of AI to create, organize, and master your study materials</p>
          <div className={styles.heroButtons}>
            <button className={styles.primaryButton}>
              Get Started
              <FaArrowRight className={styles.buttonIcon} />
            </button>
            <button className={styles.secondaryButton}>
              Watch Demo
            </button>
          </div>
        </div>
        <div className={styles.heroFeatures}>
          <div className={styles.featureCard}>
            <FaMicrophone className={styles.featureIcon} />
            <h3>Voice to Notes</h3>
            <p>Record lectures and convert them instantly to study materials</p>
          </div>
          <div className={styles.featureCard}>
            <FaPencilAlt className={styles.featureIcon} />
            <p>Scan handwritten notes and convert them to digital format</p>
          </div>
          <div className={styles.featureCard}>
            <FaBrain className={styles.featureIcon} />
            <p>Generate quizzes and flashcards with AI assistance</p>
          </div>
        </div>
      </section>

      {/* Quick Actions */}
      <section className={styles.quickActions}>
        <div className={styles.actionCard}>
          <div className={styles.actionContent}>
            <FaPlus className={styles.actionIcon} />
            <div>
              <h3>Create New Set</h3>
              <p>Start fresh with AI assistance</p>
            </div>
          </div>
          <FaArrowRight className={styles.arrowIcon} />
        </div>
        <div className={styles.actionCard}>
          <div className={styles.actionContent}>
            <FaFileAlt className={styles.actionIcon} />
            <div>
              <h3>Import Content</h3>
              <p>PDF, Word, or handwritten notes</p>
            </div>
          </div>
          <FaArrowRight className={styles.arrowIcon} />
        </div>
        <div className={styles.actionCard}>
          <div className={styles.actionContent}>
            <FaMicrophone className={styles.actionIcon} />
            <div>
              <h3>Record Lecture</h3>
              <p>Convert audio to study materials</p>
            </div>
          </div>
          <FaArrowRight className={styles.arrowIcon} />
        </div>
      </section>

      {/* Recent Activity */}
      <section className={styles.recentActivity}>
        <div className={styles.sectionHeader}>
          <h2>Recent Activity</h2>
          <button className={styles.viewAllButton}>View All</button>
        </div>
        <div className={styles.activityGrid}>
          {recentSets.map(set => (
            <div key={set.id} className={styles.activityCard}>
              <div className={styles.cardHeader}>
                <h3>{set.title}</h3>
                <span className={styles.cardMeta}>{set.cards} cards</span>
              </div>
              <div className={styles.progressSection}>
                <div className={styles.progressBar}>
                  <div 
                    className={styles.progressFill} 
                    style={{ width: `${set.progress}%` }}
                  />
                </div>
                <span className={styles.progressText}>{set.progress}% Complete</span>
              </div>
              <div className={styles.cardFooter}>
                <span className={styles.timestamp}>{set.lastAccessed}</span>
                <button className={styles.continueButton}>
                  Continue <FaArrowRight />
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Features Showcase */}
      <section className={styles.featuresShowcase}>
        <div className={styles.featureBlock}>
          <div className={styles.featureContent}>
            <h2>Smart Study Materials</h2>
            <p>Our AI analyzes your content and automatically generates:</p>
            <ul className={styles.featureList}>
              <li>Comprehensive flashcard sets</li>
              <li>Practice quizzes with explanations</li>
              <li>Summary notes and key points</li>
              <li>Custom study guides</li>
            </ul>
            <button className={styles.featureButton}>
              Try It Now <FaArrowRight />
            </button>
          </div>
          <div className={styles.featureVisual}>
            {/* Visual placeholder for feature illustration */}
          </div>
        </div>

        <div className={styles.featureBlock}>
          <div className={styles.featureVisual}>
            {/* Visual placeholder for feature illustration */}
          </div>
          <div className={styles.featureContent}>
            <h2>Multi-Format Support</h2>
            <p>Import your study materials in any format:</p>
            <ul className={styles.featureList}>
              <li>PDF documents and textbooks</li>
              <li>Word documents and notes</li>
              <li>Handwritten notes via OCR</li>
              <li>Audio recordings and lectures</li>
            </ul>
            <button className={styles.featureButton}>
              Learn More <FaArrowRight />
            </button>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className={styles.statsSection}>
        <div className={styles.statCard}>
          <span className={styles.statNumber}>50K+</span>
          <span className={styles.statLabel}>Study Sets Created</span>
        </div>
        <div className={styles.statCard}>
          <span className={styles.statNumber}>5K+</span>
          <span className={styles.statLabel}>Active Students</span>
        </div>
        <div className={styles.statCard}>
          <span className={styles.statNumber}>92%</span>
          <span className={styles.statLabel}>User Satisfaction</span>
        </div>
      </section>
      <AIStudyAssistant />
    </div>
  );
};

export default HomePage;
